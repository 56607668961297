<template>
  <div>
    <AppHeader />

    <div id="clean" class="section">
      <div class="flex">
        <div id="error-404">404</div>
      </div>
    </div>
    <!--[ #clean ]-->

    <AppSprite />
  </div>
</template>

<script>
import AppHeader from "@/components/containers/AppHeader";
import AppSprite from "@/components/containers/AppSprite";
export default {
  name: "NotFound",
  components: { AppHeader, AppSprite },
};
</script>
